.page-board-meetings {
    .content-wrapper {
        @include maxwidth();

        @include accessibility($breakpoint_to_sidebar) {
            display: flex;
        }

        > div {
            margin-bottom: $margin_sections;

            @include accessibility($breakpoint_to_sidebar) {
                flex: 1 1 auto;
                margin-bottom: 0;
            }

            .block {
                padding-right: 0;
                padding-left: 0;

                &:first-child :first-child {
                    margin-top: 0;
                }
            }

            .meeting-listing {
                list-style: none;
                margin: .75rem 0 3rem;
                padding: .75rem;
                border: 1px solid #dfdfdf;
                border-radius: 2px;

                li {
                    $breakpoint_to_flex : 40rem;
                    margin: 0;
                    padding: 1rem 0;

                    @include accessibility($breakpoint_to_flex) {
                        display: flex;
                        align-items: flex-start;
                    }

                    &:not(:last-of-type) {
                        border-bottom: 1px solid #d8d8d8;

                        @at-root body[data-contrast-mode=on] & {
                            border-color: white;
                        }
                    }

                    time {
                        display: block;
                        min-width: 7.5rem;
                        max-width: 7.5rem;
                        margin-bottom: 1.25rem;
                        padding: .25rem;
                        color: white;
                        font-weight: 700;
                        font-size: 1.5rem;
                        text-align: center;
                        text-transform: uppercase;
                        background-color: $color_quaternary;
                        border-radius: 2px;

                        @at-root body[data-contrast-mode=on] & {
                            background-color: black;
                            border: 1px solid white;
                        }

                        @include accessibility($breakpoint_to_flex) {
                            flex: 0 0 7.5rem;
                            margin-right: 1.25rem;
                            margin-bottom: 0;
                        }
                    }

                    .title {
                        flex: 1 1 auto;
                        font-size: .875rem;
                    }

                    .downloads {
                        flex: 0 1 auto;
                        margin-top: 1.25rem;

                        @include accessibility($breakpoint_to_flex) {
                            margin-top: 0;
                            margin-left: 1.25rem;
                        }

                        a {
                            display: flex;
                            justify-content: flex-end;
                            align-items: flex-end;
                            line-height: 1.142857142857143;

                            &:not(:last-of-type) {
                                margin-bottom: .25rem;
                            }

                            span {
                                font-size: .875rem;
                                white-space: nowrap;
                            }

                            svg {
                                flex: 0 0 .9375rem;
                                min-width: .9375rem;
                                max-width: .9375rem;
                                margin-left: .75rem;

                                path {
                                    fill: currentColor;
                                }
                            }
                        }
                    }
                }
            }
        }

        aside {
            @include accessibility($breakpoint_to_sidebar) {
                flex: 1 1 25rem;
                @include scale(min-width, 20rem, $breakpoint_to_sidebar, 25rem, $max_width_content);
                max-width: 25rem;
                margin-left: 5rem;
            }

            .h2 {
                margin-bottom: 1.5rem;
                padding-bottom: .75rem;
                border-bottom: 4px solid $color_quaternary;

                @at-root body[data-contrast-mode=on] & {
                    border-color: white;
                }
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin-bottom: 1rem;
                    padding-bottom: 1rem;

                    &:not(:last-of-type) {
                        border-bottom: 1px solid #d8d8d8;
                    }

                    a {
                        display: flex;
                        align-items: flex-end;
                        line-height: 1.1875;

                        svg {
                            width: 1.25rem;
                            height: 1.25rem;
                            margin-right: .75rem;

                            path {
                                fill: currentColor;
                            }
                        }
                    }
                }
            }
        }
    }
}