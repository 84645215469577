.byline {
    margin-top: 0;
    margin-bottom: 1rem;
	color: $color_quaternary;
	font-weight: 700;
	letter-spacing: .143125em;
    line-height: 1.625;
	text-transform: uppercase;

    @at-root body[data-contrast-mode=on] & {
        color: white;
    }

    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
        margin-top: 0;
    }
}