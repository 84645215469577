main {
	margin: 0;

	> article,
	> section {
		width: 100%;

		> article,
		> section {
			margin: $margin_sections 0;
		}
	}
}