.faq-listing {
    @include maxwidth();

    header.category-heading {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        padding-bottom: 1.5rem;
        color: $color_quaternary;
        border-bottom: 4px double #d7d7d7;

        @at-root body[data-contrast-mode=on] & {
            color: white;
            border-color: white;
        }

        svg,
        picture {
            width: 2.8125rem;
            height: auto;
            margin-right: 1rem;
        }

        svg path {
            fill: currentColor;
        }

        span {
            font-size: 1.875rem;
            line-height: 1.166666666666667;
        }
    }

    .block {
        padding-right: 0;
        padding-left: 0;
    }
}