.site-footer {
    .tower-planes {
        display: none;

        @media (prefers-reduced-motion: no-preference) {
            display: block;
        }

        @at-root body[data-contrast-mode=on] & {
            display: none;
        }

        img,
        video {
            display: block;
            width: 100%;
            height: auto;
            margin-bottom: -1px;

            &.desktop {
                display: none;
            }

            @include accessibility(40rem) {
                &.mobile {
                    display: none;
                }

                &.desktop {
                    display: block;
                }
            }
        }
    }

    .primary {
        position: relative;
        color: white;
        background-image: linear-gradient(180deg, $color_primary 1%, #002b4c 96%);

        @at-root body[data-contrast-mode=on] & {
            background-color: black;
            background-image: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-image: url(/dist/svgs/fort-wayne-international-airport-icon-white.svg);
            background-position: center 1.625rem;
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 1;
            opacity: .04;

            @include accessibility(64rem) {
                background-size: contain;
            }
        }

        .wrapper {
            @include maxwidth();
            position: relative;
            z-index: 2;

            hr {
                margin: 3rem 0;

                &::before,
                &::after {
                    display: none;
                }
            }

            .newsletter {
                padding-top: 4.5rem;
                text-align: center;

                header {
                    margin-bottom: 1rem;
                    color: white;
                }

                form {
                    @include maxwidth(30rem);
                    display: flex;
                    justify-content: center;
                    padding: 0;

                    input {
                        flex: 1 1 auto;
                        width: auto;
                        margin-right: .375rem;
                        margin-bottom: 0;
                        padding: 1.0625rem .75rem;
                        font-size: .75rem;
                        border: 1px solid #ededed;
                        border-radius: 2px;

                        @at-root body[data-contrast-mode=on] & {
                            background-color: black;
                            border-color: white;
                        }

                        &::placeholder {
                            font-style: italic;

                            @at-root body[data-contrast-mode=on] & {
                                color: white;
                            }
                        }
                    }

                    button {
                        flex: 0 0 auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: auto;
                        padding: .75rem 1.75rem;
                        color: $color_primary;
                        background-color: white;
                        border: 0;
                        border-radius: 2px;

                        @at-root body[data-contrast-mode=on] & {
                            color: white;
                            background-color: black;
                            border: 1px solid white;
                        }

                        svg path {
                            fill: currentColor;
                        }
                    }
                }
            }

            .top {
                $breakpoint_to_horizontal : 32rem;
                font-size: .875rem;
                letter-spacing: .01em;
                line-height: 1.642857142857143;
                text-align: center;

                @include accessibility($breakpoint_to_horizontal) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-align: left;
                }

                .logo {
                    display: flex;
                    width: 100%;
                    max-width: 15.5rem;
                    margin: 0 auto 2rem;

                    @include accessibility($breakpoint_to_horizontal) {
                        margin-right: 0;
                        margin-bottom: 0;
                        margin-left: 0;
                    }

                    svg {
                        display: block;
                        width: 100%;
                        height: auto;

                        path,
                        polyline,
                        polygon {
                            fill: white;
                        }
                    }
                }

                .contact-info {
                    $breakpoint_to_flex : 50rem;

                    @include accessibility($breakpoint_to_flex) {
                        display: flex;
                    }

                    .address {
                        margin-bottom: 2rem;

                        @include accessibility($breakpoint_to_horizontal) {
                            margin-bottom: 1rem;
                        }

                        @include accessibility($breakpoint_to_flex) {
                            margin-right: 5.625rem;
                            margin-bottom: 0;
                        }
                    }

                    .phone-email {
                        a {
                            position: relative;
                            color: white;
                            font-weight: 700;
                            text-decoration: none;

                            &::after {
                                content: '';
                                position: absolute;
                                top: 100%;
                                left: 0;
                                width: 0;
                                border-bottom: 1px solid currentColor;

                                @media (prefers-reduced-motion: no-preference) {
                                    transition: width .25s ease-in-out;
                                }
                            }

                            &:hover,
                            &:focus {
                                &::after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            .middle {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .column {
                    flex: 1 1 100%;
                    margin-bottom: 2rem;
                    text-align: center;

                    @include accessibility(36rem) {
                        flex: 1 1 50%;
                    }

                    @include accessibility(54rem) {
                        flex: 1 1 auto;
                        margin-bottom: 0;
                        text-align: left;
                    }

                    a:not(.rd-button) {
                        position: relative;
                        color: white;
                        text-decoration: none;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 0;
                            border-bottom: 1px solid currentColor;

                            @media (prefers-reduced-motion: no-preference) {
                                transition: width .25s ease-in-out;
                            }
                        }

                        &:hover,
                        &:focus {
                            &::after {
                                width: 100%;
                            }
                        }
                    }

                    .featured {
                        margin-bottom: 1.25rem;

                        a {
                            font-weight: 700;
                            font-size: 1.125rem;
                            line-height: 1.555555555555556;
                        }
                    }

                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;

                        li {
                            margin-bottom: 0;

                            a {
                                font-size: .8125rem;
                                line-height: 2.153846153846154;
                            }
                        }
                    }
                }
            }

            .bottom {
                padding-bottom: 2rem;
                text-align: center;

                header {
                    font-weight: 700;
                    letter-spacing: .00875em;
                    line-height: 1.1875;
                }

                .social-media {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    margin: 1rem auto;

                    a {
                        flex: 1 1 2.4375rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 2.4375rem;
                        max-width: 2.4375rem;
                        height: 2.4375rem;
                        margin: .375rem;
                        color: white;
                        border: 1px solid currentColor;
                        border-radius: 50%;

                        svg {
                            width: 40%;
                            height: 40%;

                            path {
                                fill: currentColor;
                            }
                        }

                        &:hover,
                        &:focus {
                            color: $color_tertiary;
                            background-color: white;

                            @at-root body[data-contrast-mode=on] & {
                                color: black;
                            }
                        }
                    }
                }

                .links-copyright {
                    $breakpoint_to_horizontal : 40rem;
                    font-size: .875rem;
                    letter-spacing: .008571428571429em;

                    @include accessibility($breakpoint_to_horizontal) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .links {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        a {
                            position: relative;
                            margin: .6875rem;
                            color: white;
                            text-decoration: none;

                            @include accessibility($breakpoint_to_horizontal) {
                                margin: 0 1.375rem 0 0;
                            }

                            &::after {
                                content: '';
                                position: absolute;
                                top: 100%;
                                left: 0;
                                width: 0;
                                border-bottom: 1px solid currentColor;

                                @media (prefers-reduced-motion: no-preference) {
                                    transition: width .25s ease-in-out;
                                }
                            }

                            &:hover,
                            &:focus {
                                &::after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}