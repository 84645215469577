.block.site-search {
    h2 {
        margin-top: 0;
    }
}

form.site-search {
    display: flex;

	input {
        margin-right: .25rem;
		padding: .5rem 1rem;
        color: #696969;
        font-size: .75rem;
        border: 1px solid #ededed;
        border-radius: 2px;

        @at-root body[data-contrast-mode=on] & {
            color: white;
            background-color: black;
            border-color: white;
        }

        &::placeholder {
            font-style: italic;

            @at-root body[data-contrast-mode=on] & {
                color: white;
            }
        }
	}

	button {
		@extend .rd-button;
        padding: .8125rem 1.5rem;
        border-radius: 2px;

        @media (prefers-reduced-motion: no-preference) {
            transition: all .25s ease-in-out;
        }
	}
}