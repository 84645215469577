figure,
picture {
	display: block;
	margin: 0;

	img,
	video,
	iframe {
		display: block;
		width: 100%;
		height: auto;
	}

	&.aspect {
		position: relative;
		width: 100%;
		height: 0;
		overflow: hidden;

		&.portrait		{ padding-bottom: 160%; }
		&.square		{ padding-bottom: 100%; }
		&.traditional	{ padding-bottom: 75%; }
		&.computer		{ padding-bottom: 62.5%; }
		&.hd,
		&.video,
		&.landscape		{ padding-bottom: 56.25%; }
		&.widescreen	{ padding-bottom: 54%; }
		&.cinema		{ padding-bottom: 41.84%; }
		&.banner		{ padding-bottom: 25%; }

        /**
         *      Supported by Safari 15 as of September 2021 (https://caniuse.com/mdn-css_properties_aspect-ratio)
         *      Safari 16 likely to be released around September 2022 so fallbacks above can be removed then
         *      https://en.wikipedia.org/wiki/Safari_version_history
         */
		@supports (aspect-ratio: 1 / 1) {
			height: auto;
			padding-bottom: 0 !important;

			&.portrait		{ aspect-ratio: 10 / 16; }
			&.square		{ aspect-ratio: 1 / 1; }
			&.traditional	{ aspect-ratio: 4 / 3; }
			&.computer		{ aspect-ratio: 16 / 10; }
			&.hd,
			&.video,
			&.landscape		{ aspect-ratio: 16 / 9; }
			&.widescreen	{ aspect-ratio: 1.85 / 1; }
			&.cinema		{ aspect-ratio: 2.39 / 1; }
			&.banner		{ aspect-ratio: 4 / 1; }
		}

		img,
        svg,
		video {
			object-fit: cover;
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;

			&.contain {
				object-fit: contain;
			}
		}

        &.contain {
            img,
            svg,
            video {
                object-fit: contain;
            }
        }
	}
}
