.featured-news {
    @include maxwidth();

    .wrapper {
        $breakpoint_to_2_columns : 60rem;
        @include columns(3.625rem, 4.5rem, (
            $breakpoint_to_2_columns : 2
        ));

        > div:first-child .card.news {
            a {
                display: block;

                .img {
                    flex: 1 1 100%;
                    min-width: 100%;
                    width: 100%;
                    max-width: none;
                    margin-bottom: 2.375rem;

                    @include accessibility(32rem) {
                        margin-bottom: 1.25rem;
                    }

                    &:empty {
                        width: 100%;
                        height: 0;
                        padding-bottom: 75%;

                        @supports (aspect-ratio: 4 / 3) {
                            aspect-ratio: 4 / 3;
                            height: auto;
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }

        > div:last-child .card.news {
            margin-bottom: 4.5rem;

            @include accessibility($breakpoint_to_2_columns) {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.filter-bar.news {
    margin-bottom: 1.25rem;

    + hr {
        max-width: 73rem;
    }
}

.news-listing {
    @include maxwidth();

    .wrapper {
        @include columns(3.625rem, 4.5rem, (
            60rem : 2
        ));
    }
}