&.icon-text {
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.375rem;
        height: 4.375rem;
        margin-right: auto;
        margin-left: auto;

        svg {
            display: block;
            width: 100%;
        }
    }
}