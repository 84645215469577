.airline-grid {
    @include maxwidth();

    .wrapper {
        @include columns(9rem, 6.25rem, (
            50rem : 2
        ));

        > div {
            text-align: center;

            .logo-wrapper {
                max-width: 65%;
                margin: 0 auto;

                .logo {
                    position: relative;
                    height: 0;
                    padding-bottom: 38%;

                    @supports (aspect-ratio: 50 / 19) {
                        aspect-ratio: 50 / 19;
                        display: flex;
                        align-items: flex-end;
                        height: auto;
                        padding-bottom: 0;
                    }

                    > svg,
                    > picture {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: auto;

                        @supports (aspect-ratio: 50 / 19) {
                            position: relative;
                            bottom: auto;
                            left: auto;
                        }
                    }
                }
            }

            p {
                line-height: 1.833333333333333;
            }

            .links {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                a {
                    margin: .5rem 1.5rem;

                    @at-root body[data-contrast-mode=on] & {
                        svg polyline {
                            stroke: white;
                        }
                    }
                }
            }
        }
    }
}