&.airport-project {
    .image {
        position: relative;
        display: block;
        height: 0;
        padding-bottom: 62.5%;
        background-color: #d8d8d8;

        @supports (aspect-ratio: 16 / 10) {
            aspect-ratio: 16 / 10;
            height: auto;
            padding-bottom: 0;
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 25%;
            max-width: 7.5rem;
            height: 100%;
            background: transparent url(/dist/svgs/fort-wayne-international-airport-icon-white.svg) 50% 50% / 100% no-repeat scroll;
            z-index: 1;
            transform: translate(-50%, -50%);
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(#757575, .75);
            opacity: 0;
            z-index: 3;

            @media (prefers-reduced-motion: no-preference) {
                transition: all .25s ease-in-out;
            }
        }

        picture {
            position: relative;
            z-index: 2;
        }

        .rd-button {
            position: absolute;
            top: 50%;
            left: 50%;
            color: $color_primary;
            background-color: white;
            border-color: white;
            white-space: nowrap;
            opacity: 0;
            z-index: 4;
            transform: translate(-50%, -50%);

            @at-root body[data-contrast-mode=on] & {
                color: white;
                background-color: black;
            }

            @media (prefers-reduced-motion: no-preference) {
                transition: all .25s ease-in-out;
            }
        }

        &:hover,
        &:focus {
            &::after {
                opacity: 1;
            }

            .rd-button {
                opacity: 1;
            }
        }
    }

    header {
        margin-top: 1rem;
    }

    p {
        color: #383838;
        font-weight: 400;

        @at-root body[data-contrast-mode=on] & {
            color: white;
        }
    }
}