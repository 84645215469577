.tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: 4px double #d5d5d5;

    a {
        flex: 1 1 auto;
        margin: .25rem;
        padding: 1.375rem 2.5rem;
        color: $color_tertiary;
        text-align: center;
        background-color: #f4f4f4;
        border-radius: 4px 4px 0 0;

        @at-root body[data-contrast-mode=on] & {
            color: white;
            background-color: black;
            border: 1px solid white;
        }

        @media (prefers-reduced-motion: no-preference) {
            transition: all .25s ease-in-out;
        }

        &.active,
        &:hover,
        &:focus {
            color: white;
            background-color: $color_tertiary;

            @at-root body[data-contrast-mode=on] & {
                color: black;
                background-color: white;
            }
        }
    }

    &.left {
        justify-content: flex-start;

        a {
            flex: 0 1 auto;
        }
    }
}

.tab-content {
    display: none;
    scroll-margin-top: #{$height_nav - 2};

    @include accessibility($breakpoint_to_desktop_nav) {
        scroll-margin-top: #{$height_nav + 1};
    }

    &[aria-hidden=false] {
        display: block;
        padding: 3.5rem 0;
    }
}