.block {
	.headline {
		margin-bottom: 2rem;
		text-align: center;
	}

	.desc {
		max-width: 50rem;
		margin-right: auto;
		margin-left: auto;
		text-align: center;
	}

	@import 'blocks/call-to-action';
    @import 'blocks/company-logos';
    @import 'blocks/featured-latest-news';
	@import 'blocks/form';
	@import 'blocks/frequently-asked-questions';
	@import 'blocks/full-bleed';
	@import 'blocks/gallery';
    @import 'blocks/google-map';
    @import 'blocks/grid';
    @import 'blocks/image';
    @import 'blocks/large-image-grid';
	@import 'blocks/search-results';
    @import 'blocks/social-media-feed';
	@import 'blocks/testimonials';
	@import 'blocks/text';
    @import 'blocks/text-downloads';
    @import 'blocks/text-frequently-asked-questions';
    @import 'blocks/text-icons';
	@import 'blocks/text-media';
    @import 'blocks/text-testimonials';
    @import 'blocks/third-party-embed';
	@import 'blocks/videos';
}