&.news {
    a {
        $breakpoint_to_flex : 32rem;
        color: #696969;
        text-decoration: none;

        @at-root body[data-contrast-mode=on] & {
            color: white;
        }

        @include accessibility($breakpoint_to_flex) {
            display: flex;
        }

        .img {
            position: relative;
            margin-bottom: 2rem;

            &:empty {
                width: 100%;
                height: 0;
                padding-bottom: 75%;

                @supports (aspect-ratio: 4 / 3) {
                    aspect-ratio: 4 / 3;
                    height: auto;
                    padding-bottom: 0;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    padding-bottom: 75%;
                    background-color: #d8d8d8;
                    background-image: url(/dist/svgs/fort-wayne-international-airport-icon-white.svg);
                    background-position: center;
                    background-size: 75%;
                    background-repeat: no-repeat;

                    @supports (aspect-ratio: 4 / 3) {
                        aspect-ratio: 4 / 3;
                        padding-bottom: 0;
                    }
                }
            }

            @include accessibility($breakpoint_to_flex) {
                flex: 1 1 11.25rem;
                min-width: 11.25rem;
                max-width: 11.25rem;
                margin-right: 2rem;
                margin-bottom: 0;
            }
        }

        .copy {
            .category {
                color: $color_quaternary;
                font-weight: 700;
                letter-spacing: .09375em;
                line-height: 1.625;
                text-transform: uppercase;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                }

                + header {
                    margin-top: .75rem;
                }
            }

            header {
                margin: 0 0 .75rem;
                color: $color_primary;
                font-weight: 700;
                font-size: 1.375rem;
                line-height: 1.2;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                }
            }

            p {
                margin: 0
            }

            time {
                position: relative;
                display: block;
                color: #757575;
                line-height: 1.857142857142857;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                }
            }
        }
    }
}