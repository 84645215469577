.payment-center-form-links {
    @include maxwidth();

    .block.text + & {
        margin-top: -3rem;
    }

    .wrapper {
        @include columns(2.25rem, 2.25rem, (
            48rem : 2,
            64rem : 3
        ));

        a {
            position: relative;
            padding: 1.25rem 2.5rem 2.875rem;
            color: $color_tertiary;
            border: 3px solid $color_tertiary;
            border-radius: 5px;

            @at-root body[data-contrast-mode=on] & {
                color: white;
                border-color: white;
            }

            @media (prefers-reduced-motion: no-preference) {
                transition: all .25s ease-in-out;
            }

            .icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 5.625rem;
                height: 5.625rem;
                margin-bottom: 1rem;
                color: $color_quaternary;
                border: 3px solid currentColor;
                border-radius: 50%;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                }

                @media (prefers-reduced-motion: no-preference) {
                    transition: all .25s ease-in-out;
                }

                svg,
                picture {
                    width: 55%;
                    height: 55%;
                }

                svg path {
                    fill: currentColor;
                }
            }

            span {
                color: inherit;
                font-weight: 500;
                font-size: 2rem;
                line-height: 1.125;
            }

            > svg {
                position: absolute;
                right: 2.5rem;
                bottom: 1.25rem;

                path {
                    fill: currentColor;
                }
            }

            &:hover,
            &:focus {
                color: white;
                background-color: $color_tertiary;

                .icon {
                    color: white;
                }

                @at-root body[data-contrast-mode=on] & {
                    color: black;
                    background-color: white;

                    .icon {
                        color: black;
                    }
                }
            }
        }
    }
}