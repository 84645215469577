.featured-project {
    @include maxwidth();
    margin-top: 2.375rem;

    .wrapper {
        position: relative;
        padding-top: 12.5rem;
        @include scale(padding-right, 1.25rem, 20rem, 2rem, 60rem);
        padding-bottom: 2.25rem;
        @include scale(padding-left, 1.25rem, 20rem, 2rem, 60rem);
        overflow: hidden;

        picture {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            z-index: 1;
            transform: translate(-50%, -50%);

            img {
                object-fit: cover;
                height: 100%;
            }
        }

        .copy {
            position: relative;
            max-width: 38rem;
            padding-top: 2.5rem;
            @include scale(padding-right, 1.25rem, 20rem, 2.5rem, 60rem);
            padding-bottom: 2.5rem;
            @include scale(padding-left, 1.25rem, 20rem, 2.5rem, 60rem);
            background-color: rgba(white, .85);
            z-index: 2;

            @at-root body[data-contrast-mode=on] & {
                color: white;
                background-color: black;
            }

            header {
                color: $color_tertiary;
                @include scale(font-size, 1.75rem, 20rem, 2.8125rem, 60rem);
                line-height: 1.18;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                }
            }

            p {
                margin: 1.25rem 0 2rem;
            }
        }
    }
}

.airport-project-listing {
    @include maxwidth();

    .wrapper {
        @include columns(2.5rem, 2.5rem, (
            50rem : 2
        ));
    }
}