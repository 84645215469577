&.solicitations-bidding-project {
    padding: 1.5rem 2rem;
    border: 1px solid #d7d7d7;
    border-radius: 2px;

    .project-id {
        margin-top: .5rem;
        color: $color_quaternary;
        font-weight: 700;
        text-transform: uppercase;

        @at-root body[data-contrast-mode=on] & {
            color: white;
        }
    }

    .downloads,
    .addendums {
        margin-top: 2rem;

        header {
            position: relative;
            margin-bottom: 1rem;
            padding-bottom: .5rem;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 50%;
                height: 3px;
                background-color: $color_quaternary;

                @at-root body[data-contrast-mode=on] & {
                    background-color: white;
                }
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                padding-bottom: .5rem;

                &:not(:last-of-type) {
                    margin-bottom: .5rem;
                    border-bottom: 1px solid #d7d7d7;
                }

                a {
                    display: inline-flex;
                    align-items: flex-end;
                    line-height: 1;

                    svg {
                        width: 1.375rem;
                        height: 1.375rem;
                        margin-right: .75rem;

                        path {
                            fill: currentColor;
                        }
                    }
                }
            }
        }

        + .rd-button {
            margin-top: 2rem;
        }
    }
}