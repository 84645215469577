.table-overflow {
	width: 100%;
	margin-bottom: 2rem;
	overflow-x: auto;

	table {
		min-width: 40rem;
	}
}

table {
	// table-layout: fixed;
	border-collapse: collapse;
	width: 100%;
	// border: 1px solid #dbdbdb;

	thead {
		tr {
			border-bottom: 1px solid #979797;

			th {
				padding: .75rem;
                color: #383838;
				font-weight: 700;
				font-size: 1.125rem;
				font-family: inherit;
				text-align: left;
				border-bottom: 2px solid #979797;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                    border-bottom-color: white;
                }
			}
		}
	}

	tbody {
		tr {
			td {
				padding: 1rem .75rem;

				&.no-results-message {
					padding: 4rem 0;
					text-align: center;
				}
			}

			&:nth-of-type(even) td {
				background-color: rgba(#d8d8d8, .12);
                border-top: 1px solid rgba(#979797, .12);
                border-bottom: 1px solid rgba(#979797, .12);

                &:first-child {
                    border-left: 1px solid rgba(#979797, .12);
                }

                &:last-child {
                    border-right: 1px solid rgba(#979797, .12);
                }

                @at-root body[data-contrast-mode=on] & {
                    background-color: transparent;
                    border-color: white;
                }
			}
		}
	}
}