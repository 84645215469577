&.videos {
	@include maxwidth();

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: -1rem -1rem 0;

		.video {
			flex: 1 1 100%;
			margin: 1rem;

			@include accessibility(50rem) {
				flex: 1 1 50%;
				max-width: calc((100% - 4rem) / 2.0001);

				// If odd number of videos, show the first one at full-width!
				&:nth-child(1):nth-last-child(odd) {
					flex: 1 1 100%;
					max-width: 100%;
				}
			}
		}
	}
}