.rd-lightbox {
	visibility: hidden;
	position: fixed;
	top: 50vh;
	left: 50vw;
	width: 0;
	height: 0;
	color: white;
	background-color: rgba(black, .9);
	overflow: hidden;
	opacity: 0;
	z-index: 999999;

    @media (prefers-reduced-motion: no-preference) {
	    transition: all .25s ease-in-out;
    }

	&[aria-hidden=false] {
		visibility: visible;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		opacity: 1;
	}

	.toolbar {
		display: flex;
		justify-content: space-between;
		padding: 0 0 0 1rem;

		.pages {
			display: inline-flex;
			align-items: center;

			.sep {
				padding: 0 .3125rem 0 .25rem;
			}
		}

		.links {
			display: inline-flex;
			align-items: center;

			a {
				padding: .875rem;
				color: white;
				text-decoration: none;

                @media (prefers-reduced-motion: no-preference) {
				    transition: all .25s ease-in-out;
                }

				svg {
					display: block;
					width: 1.25rem;
					height: 1.25rem;

					path {
						fill: white;
					}
				}

				&:hover,
				&:focus {
					background-color: rgba(black, .6);
				}
			}
		}
	}

	.content {
		display: flex;
		height: calc(100% - 3rem);

		.rd-lightbox-prev,
		.rd-lightbox-next {
			flex: 0 1 auto;
			display: inline-flex;
			align-items: center;
			padding: .875rem;
			color: white;
			text-decoration: none;

            @media (prefers-reduced-motion: no-preference) {
			    transition: all .25s ease-in-out;
            }

			svg {
				display: block;
				width: 1.25rem;
				height: 1.25rem;

				path {
					fill: white;
				}
			}

			&:hover,
			&:focus {
				background-color: rgba(black, .6);
			}
		}

		.gallery {
			flex: 1 1 auto;
			position: relative;

			> div {
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				max-width: 80rem;
				max-height: 100%;
				transform: translate(-50%, -50%);

                @media (prefers-reduced-motion: no-preference) {
				    transition: all .25s ease-in-out;
                }

				&:not(.active) {
					opacity: 0;
					z-index: -1;
				}

				img {
					display: block;
					max-width: 100%;
					max-height: calc(100vh - 6rem);
					margin: -3rem auto 0;
				}

				.caption {
					padding: .625rem 1rem;
					color: white;
					font-size: .875rem;
					text-align: center;
				}
			}
		}

		&[data-count="1"] {
			.rd-lightbox-prev,
			.rd-lightbox-next {
				display: none;
			}

			.gallery {
				margin: 0 3rem;
			}
		}
	}
}