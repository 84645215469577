&.text-icons {
    $breakpoint_to_flex : 60rem;
	@include maxwidth();

	@include accessibility($breakpoint_to_flex) {
        display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

    > .text {
        background-color: white;

        @at-root body[data-contrast-mode=on] & {
            background-color: black;
        }

		@include accessibility($breakpoint_to_flex) {
			flex: 1 1 46%;
            min-width: 46%;
            max-width: 46%;
		}

        .h2, .h3, .h4, .h5, .h6 {
            &:first-child {
                margin-top: 0;
            }
        }

        .buttons {
            margin-top: 2.5rem;
        }
	}

	.icons {
		position: relative;
        margin: 3rem 0 0;

		@include accessibility($breakpoint_to_flex) {
			flex: 1 1 46%;
            min-width: 46%;
            max-width: 46%;
            margin: 0;
		}

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            @include accessibility(36rem) {
                column-count: 2;
                column-gap: 4rem;
            }

            @include accessibility($breakpoint_to_flex) {
                column-count: 1;
            }

            @include accessibility(72rem) {
                column-count: 2;
            }

            li {
                break-inside: avoid;
                margin-bottom: 1rem;
                padding-bottom: .75rem;

                > div {
                    display: flex;
                    align-items: center;
                    font-weight: 700;

                    .icon {
                        width: 2.1875rem;
                        height: 2.1875rem;
                        margin-right: 1rem;

                        svg {
                            display: block;
                            width: 100%;
                            height: 100%;

                            @at-root body[data-contrast-mode=on] & {
                                path {
                                    fill: white;
                                }
                            }
                        }
                    }
                }
            }
        }
	}
}