.news-detail-header {
    $breakpoint_to_flex : 50rem;
    @include maxwidth();
    margin-bottom: 2rem;

    @include accessibility($breakpoint_to_flex) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .left {
        h1 {
            margin: 0 0 .5rem;
        }

        div {
            color: #757575;

            @at-root body[data-contrast-mode=on] & {
                color: white;
            }
        }
    }

    .right {
        margin-top: 2rem;

        @include accessibility($breakpoint_to_flex) {
            margin-top: 0;
            margin-left: 2rem;
        }
    }

    + .block {
        margin-top: 3rem;
    }
}

.prev-next {
    $breakpoint_to_flex : 32rem;
    @include maxwidth();
    margin-top: 8rem;

    @include accessibility($breakpoint_to_flex) {
        display: flex;
        justify-content: space-between;
    }

    .prev,
    .next {
        display: flex;
        flex-direction: column;

        @include accessibility($breakpoint_to_flex) {
            max-width: 44%;
        }

        header {
            margin: 1.5rem 0 1.25rem;
            color: $color_primary;
            font-weight: 700;
            font-size: 1.375rem;
            line-height: 1.136363636363636;

            @at-root body[data-contrast-mode=on] & {
                color: white;
            }
        }

        time {
            color: #757575;

            @at-root body[data-contrast-mode=on] & {
                color: white;
            }
        }
    }

    .prev {
        align-items: flex-start;
        text-align: left;
    }

    .next {
        margin-top: 2.5rem;
        align-items: flex-end;
        text-align: right;

        @include accessibility($breakpoint_to_flex) {
            margin-top: 0;
        }
    }
}