&.text-frequently-asked-questions {
	$breakpoint_to_flex : 60rem;
	@include maxwidth();

	@include accessibility($breakpoint_to_flex) {
        display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

    > .text {
        background-color: white;

        @at-root body[data-contrast-mode=on] & {
            background-color: black;
        }

		@include accessibility($breakpoint_to_flex) {
			flex: 1 1 32%;
            min-width: 32%;
            max-width: 32%;
		}

        .h2, .h3, .h4, .h5, .h6 {
            &:first-child {
                margin-top: 0;
            }
        }

        .buttons {
            margin-top: 2.5rem;
        }
	}

	.block.frequently-asked-questions {
		position: relative;
        margin: 3rem 0 0;
        padding: 1rem 2.25rem;
        border: 1px solid #a7a7a7;

		@include accessibility($breakpoint_to_flex) {
			flex: 1 1 65%;
            min-width: 65%;
            max-width: 65%;
            margin: 0;
		}
	}
}