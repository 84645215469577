&.gallery {
	@include maxwidth();

	.wrapper {
		@include columns(.375rem, .375rem, (
			22.0625rem  : 2,
			42.4375rem  : 3,
			62.8125rem  : 4
		));
		margin-top: .375rem;

		> a {
			color: white;
			background-color: #151515;
			cursor: pointer;

			figure,
			picture {
				> img {
					opacity: 1;
					transform-origin: center;

                    @media (prefers-reduced-motion: no-preference) {
					    transition: all .5s ease-in-out;
                    }
				}

				> svg {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 40%;
					height: 40%;
					max-width: 3.5rem;
					max-height: 3.5rem;
					z-index: 2;
					opacity: 0;
					transform: translate(-50%, -50%);

                    @media (prefers-reduced-motion: no-preference) {
					    transition: all .25s ease-in-out;
                    }

					path {
						fill: currentColor;
					}
				}
			}

			&:hover,
			&:focus {
				figure,
				picture {
					img {
						opacity: .45;
						transform: scale(1.05);
					}

					svg {
						opacity: 1;
					}
				}
			}
		}
	}
}