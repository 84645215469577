.page-parking-transportation {
    .content-wrapper {
        @include maxwidth();

        @include accessibility($breakpoint_to_sidebar) {
            display: flex;
        }

        > div {
            margin-bottom: $margin_sections;

            @include accessibility($breakpoint_to_sidebar) {
                flex: 1 1 auto;
                margin-bottom: 0;
            }

            .block {
                padding-right: 0;
                padding-left: 0;

                &:first-child :first-child {
                    margin-top: 0;
                }
            }

            .parking-lot-map-image {
                margin: 1.5rem 0;
            }
        }

        aside {
            @include accessibility($breakpoint_to_sidebar) {
                flex: 1 1 50%;
                min-width: 24rem;
                max-width: 30rem;
                margin-left: 7.5rem;
            }

            header {
                margin-bottom: 2rem;
            }

            .block.frequently-asked-questions {
                padding-right: 0;
                padding-left: 0;

                .wrapper {
                    border-bottom: 0;

                    .toggler {
                        padding: .9375rem 1.3125rem;
                        color: white;

                        .title {
                            font-weight: 700;
                            font-size: 1.3125rem;
                        }
                    }

                    .toggle {
                        padding: 1.25rem 2.75rem;

                        p {
                            margin: 0;

                            + table {
                                margin-top: 1.25rem;
                            }
                        }

                        table {
                            table-layout: auto;
                            border: 0;

                            tbody tr td {
                                padding: .125rem;
                                font-weight: 700;
                                background-color: transparent !important;
                            }
                        }
                    }
                }

                .wrapper:first-child .toggler {
                    border-radius: 5px 5px 0 0;
                }

                .wrapper:nth-child(3n + 1) .toggler {
                    background-color: $color_primary;

                    @at-root body[data-contrast-mode=on] & {
                        background-color: black;
                        border: 1px solid white;
                    }
                }

                .wrapper:nth-child(3n + 2) .toggler {
                    color: black;
                    background-color: $color_quinary;

                    @at-root body[data-contrast-mode=on] & {
                        color: white;
                        background-color: black;
                        border: 1px solid white;
                    }
                }

                .wrapper:nth-child(3n + 3) .toggler {
                    background-color: $color_quaternary;

                    @at-root body[data-contrast-mode=on] & {
                        background-color: black;
                        border: 1px solid white;
                    }
                }

                .wrapper:last-child .toggler {
                    border-radius: 0 0 5px 5px;
                }
            }
        }
    }

    .ground-transportation {
        @include maxwidth();

        > header {
            margin-bottom: 2rem;
            text-align: center;
        }

        .tab-content {
            .wrapper {
                @include columns(5.375rem, 5.375rem, (
                    38.5rem : 2,
                    57.75rem : 3,
                    77rem : 4
                ));

                figure,
                picture,
                header,
                header + p {
                    margin-bottom: 1rem;
                }

                hr {
                    width: 2.75rem;
                    height: 3px !important;
                    margin: 1.5rem 0 !important;
                    background-color: $color_quaternary;
                    border: 0;

                    @at-root body[data-contrast-mode=on] & {
                        background-color: white;
                    }

                    &::before,
                    &::after {
                        display: none;
                    }
                }

                div {
                    margin-bottom: .375rem;
                }

                @at-root body[data-contrast-mode=on] & {
                    svg path {
                        fill: white;
                    }
                }
            }
        }
    }
}