&.frequently-asked-questions {
	@include maxwidth();

	.wrapper {
		border-bottom: 1px solid #eee;

		.toggler {
			display: flex;
			align-items: center;
			padding: 1rem 0;
            color: $color_tertiary;
			font-weight: 700;
            font-size: 1.3125rem;
			text-decoration: none;

            @at-root body[data-contrast-mode=on] & {
                color: white;
            }

			.title {
				flex: 1 1 100%;
				padding-right: 1.5rem;
				color: inherit;
				font-family: $font_family_body;

                @media (prefers-reduced-motion: no-preference) {
				    transition: all .25s ease-in-out;
                }
			}

			svg {
				width: 1rem;
				height: 1rem;
				margin: .125rem;
                transform-origin: center;

                @media (prefers-reduced-motion: no-preference) {
				    transition: all .25s ease-in-out;
                }

				path {
					fill: currentColor;
				}
			}

			&:hover,
			&.toggled {
				.title {
					color: currentColor;
				}

				svg path {
					fill: currentColor;
				}
			}

			&.toggled {
				border-bottom: 0;

				+ .toggle {
					display: block;
				}

				svg {
					transform: rotate(180deg);
				}
			}
		}

		.toggle {
			display: none;
			padding: 0 0 1rem 3.75rem;

			p {
				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}

				&:empty {
					display: none;
				}
			}
		}
	}
}