.element {
    text-align: center;

    h2, h3, h4, h5, h6 {
        margin: 1rem 0;
    }

    .rd-button {
        margin-top: 1.25rem;
    }

    @import 'elements/company-logo';
    @import 'elements/icon-text';
    @import 'elements/image-text';
    @import 'elements/text';
    @import 'elements/video-text';

    &.frequently-asked-question {
        text-align: left;
    }
}