&.grid {
    @include maxwidth();
    overflow: hidden;

    .wrapper {
        @include columns(3rem, 2.5rem, (
            36rem : 2,
            54rem : 3
        ), false);
        justify-content: center;
        margin-top: 3.5rem;

        + p {
            margin-top: 0;
        }
    }
}