&.text-media {
	$breakpoint_to_flex : 60rem;
	@include maxwidth();

	@include accessibility($breakpoint_to_flex) {
        display: flex;
		justify-content: space-between;
		align-items: flex-start;

		&.media-right {
			flex-direction: row-reverse;
		}
	}

	.media {
		position: relative;
        margin-bottom: 2rem;

		@include accessibility($breakpoint_to_flex) {
			flex: 1 1 50%;
            min-width: 50%;
            max-width: 50%;
            margin-bottom: 0;
		}

        .background {
            picture,
            video {
                width: 100%;
            }

            + .primary {
                position: relative;
                margin-top: -18%;
                width: 55%;
            }
        }

        .primary {
            box-shadow: 10px 12px 14px 0 rgba(black, .13);
        }
	}

    &.media-left .media {
        @include clearfix;

        .background + .primary {
            float: right;
            margin-right: -1rem;

            @include accessibility($breakpoint_to_flex) {
                margin-right: -2.75rem;
            }
        }
    }

    &.media-right .media .background + .primary {
        margin-left: -1rem;

        @include accessibility($breakpoint_to_flex) {
            margin-left: -2.75rem;
        }
    }

	.text {
        background-color: white;

        @at-root body[data-contrast-mode=on] & {
            background-color: black;
        }

		@include accessibility($breakpoint_to_flex) {
			flex: 1 1 40%;
            min-width: 40%;
            max-width: 40%;
		}

		.rd-button {
			margin-top: .5rem;
		}

        .meeting-listing {
            list-style: none;
            margin: .75rem 0 0;
            padding: .75rem;
            border: 1px solid #dfdfdf;
            border-radius: 2px;

            li {
                display: flex;
                align-items: flex-start;
                margin: 0;
                padding: 1rem 0;

                &:not(:last-of-type) {
                    border-bottom: 1px solid #d8d8d8;
                }

                time {
                    flex: 0 0 7.5rem;
                    display: block;
                    min-width: 7.5rem;
                    max-width: 7.5rem;
                    margin-right: 1.25rem;
                    padding: .25rem;
                    color: white;
                    font-weight: 700;
                    font-size: 1.5rem;
                    text-align: center;
                    text-transform: uppercase;
                    background-color: $color_quaternary;
                    border-radius: 2px;
                }

                .copy {
                    flex: 1 1 auto;

                    .title {
                        font-size: .875rem;
                    }

                    .downloads {
                        margin-top: .25rem;

                        a {
                            display: flex;
                            align-items: flex-end;
                            line-height: 1.142857142857143;

                            &:not(:last-of-type) {
                                margin-bottom: .25rem;
                            }

                            span {
                                font-size: .875rem;
                                white-space: nowrap;
                            }

                            svg {
                                flex: 0 0 .9375rem;
                                min-width: .9375rem;
                                max-width: .9375rem;
                                margin-left: .75rem;

                                path {
                                    fill: currentColor;
                                }
                            }
                        }
                    }
                }
            }

            + a {
                display: inline-block;
                margin-top: 1rem;
            }
        }
	}
}