&.testimonials {
	position: relative;

    &.fullWidth {
        background-color: #151515;

        > picture {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%);
            opacity: .2;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .copy {
            @include maxwidth();
            display: flex;
            justify-content: flex-start;
            padding: 10.375rem 2rem 5.9375rem;

            blockquote {
                max-width: 36.25rem;
                margin: 0;
                padding: .5rem .625rem;
                color: white;
                font-style: italic;
                font-weight: 400;
                @include scale(font-size, 1.125rem, 20rem, 1.75rem, 40rem);
                font-family: inherit;
                line-height: 1.285714285714286;
                text-align: center;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    width: 1.375rem;
                    height: 100%;
                    background-color: transparent;
                    background-image: url(/dist/svgs/featured-testimonial.svg);
                    background-position: 100% 50%;
                    background-repeat: no-repeat;
                    border-top: 2px solid white;
                    border-bottom: 2px solid white;
                    border-image: linear-gradient(to right, white 0%, white 50%, transparent 50%, transparent 100%);
                    border-image-slice: 1;
                }

                &::before {
                    left: -1.5rem;
                    transform: rotate(180deg);
                }

                &::after {
                    right: -1.5rem;
                }

                p {
                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &::before {
                        content: open-quote;
                    }

                    &::after {
                        content: close-quote;
                    }
                }
            }
        }
    }
}