.flight-statuses {
    @include maxwidth();
    margin-top: 2rem;
    margin-bottom: 2rem;

    .tabs {
        @include accessibility(40rem) {
            justify-content: flex-start;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;

            @include accessibility(40rem) {
                flex: 0 1 auto;
            }

            svg {
                width: 2.625rem;
                height: 1.5rem;
                margin-right: .5rem;

                path {
                    fill: currentColor;
                }
            }
        }
    }

    .tab-content {
        scroll-margin-top: $height_nav;
        padding: 0;
        color: #2d2d2d;
        font-size: .875rem;

        @at-root body[data-contrast-mode=on] & {
            color: white;
        }

        @include accessibility($breakpoint_to_desktop_nav) {
            scroll-margin-top: #{$height_nav + 3};
        }

        .last-updated {
            margin: 1.5rem 0;
            text-align: right;
        }

        .no-results {
            margin: $margin_sections 0;
            text-align: center;
        }

        table {
            $breakpoint_to_table : 48rem;
            display: block;

            @include accessibility($breakpoint_to_table) {
                display: table;
            }

            thead {
                display: none;

                @include accessibility($breakpoint_to_table) {
                    display: table-header-group;
                }

                tr th {
                    padding: 1.25rem;
                    color: white;
                    font-size: 1rem;
                    background-color: #383838;
                    border-bottom: 0;

                    @at-root body[data-contrast-mode=on] & {
                        background-color: black;
                    }
                }
            }

            tbody {
                display: block;

                @include accessibility($breakpoint_to_table) {
                    display: table-row-group;
                }

                tr {
                    display: block;

                    @at-root body[data-contrast-mode=on] & {
                        border-bottom: 1px solid white;

                        @include accessibility($breakpoint_to_table) {
                            border: 0;
                        }
                    }

                    @include accessibility($breakpoint_to_table) {
                        display: table-row;
                    }

                    td {
                        display: flex;
                        align-items: flex-end;
                        padding: .125rem 1.25rem;

                        @at-root body[data-contrast-mode=on] & {
                            background-color: black;

                            @include accessibility($breakpoint_to_table) {
                                border-bottom: 1px solid white;
                            }
                        }

                        &:first-child {
                            padding-top: 1.25rem;
                        }

                        &:last-child {
                            padding-bottom: 1.25rem;
                        }

                        @include accessibility($breakpoint_to_table) {
                            display: table-cell;
                            padding: 1rem 1.25rem;
                        }

                        &.origin::before {
                            content: 'Origin:\00a0\00a0';
                        }

                        &.destination::before {
                            content: 'Destination:\00a0\00a0';
                        }

                        &.airline::before {
                            content: 'Airline:\00a0\00a0';
                        }

                        &.flightnum::before {
                            content: 'Flight #:\00a0\00a0';
                        }

                        &.scheduled::before {
                            content: 'Scheduled:\00a0\00a0';
                        }

                        &.status::before {
                            content: 'Status:\00a0\00a0';
                        }

                        &.updated::before {
                            content: 'Updated:\00a0\00a0';
                        }

                        &.origin,
                        &.destination,
                        &.airline,
                        &.flightnum,
                        &.scheduled,
                        &.status,
                        &.updated {
                            &::before {
                                display: inline-block;
                                color: #2d2d2d;

                                @at-root body[data-contrast-mode=on] & {
                                    color: white;
                                }

                                @include accessibility($breakpoint_to_table) {
                                    display: none;
                                }
                            }
                        }

                        &.status {
                            &.arrived,
                            &.departed,
                            &.early,
                            &.on-time {
                                color: #5d7208;

                                @at-root body[data-contrast-mode=on] & {
                                    color: #ccff00;
                                }
                            }

                            &.canceled,
                            &.delayed,
                            &.late {
                                color: #bc3d3d;

                                @at-root body[data-contrast-mode=on] & {
                                    color: #ff0000;
                                }
                            }
                        }

                        .logo {
                            display: inline-block;
                            max-width: 5.625rem;

                            svg {
                                display: block;
                                width: 100%;
                                height: auto;
                            }
                        }
                    }

                    &.highlight td {
                        background-color: rgba($color_quinary, .4);

                        @at-root body[data-contrast-mode=on] & {
                            color: black;
                            background-color: white;

                            &.status {
                                &.arrived,
                                &.departed,
                                &.early,
                                &.on-time {
                                    color: #4f6200;
                                }

                                &.canceled,
                                &.delayed,
                                &.late {
                                    color: #af0000;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    + .block.text {
        margin-top: 0;
    }
}