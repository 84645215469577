.filter-bar {
	@include maxwidth();

	form {
		$breakpoint_to_nowrap: 45rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		label {
			display: block;
			margin-bottom: .75rem;

			@include accessibility($breakpoint_to_nowrap) {
				margin-bottom: 0;

				&:not(:first-child) {
					margin-left: .75rem;
				}
			}

			input,
			select {
				outline: none;
				display: block;
				width: 100%;
				color: #6a6a6a;
				border: 1px solid #d3d3d3;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                    border-color: white;
                }
			}

			&.search {
				flex: 1 1 100%;

				@include accessibility($breakpoint_to_nowrap) {
					max-width: 20rem;
				}

				input {
					padding: .625rem .625rem .625rem 2.5rem;
					background-image: url(/dist/svgs/search.svg);
					background-position: .625rem 50%;
					background-repeat: no-repeat;
				}
			}

			&.select {
				flex: 0 1 100%;
				background-image: url(/dist/svgs/select-chevron.svg);
				background-position: calc(100% - .625rem) 50%;
				background-repeat: no-repeat;

                @at-root body[data-contrast-mode=on] & {
                    background-image: url(/dist/svgs/select-chevron-white.svg);
                }

				@include accessibility($breakpoint_to_nowrap) {
					flex: 0 1 auto;
				}

				select {
					appearance: none;
					padding: .5rem 2.5rem .5rem .75rem;
					line-height: 1.375;
					background-color: transparent;
				}
			}
		}

		.break {
			display: none;

			@include accessibility($breakpoint_to_nowrap) {
				display: block;
				margin-left: auto;
			}
		}
	}
}