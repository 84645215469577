&.social-media-feed {
    @include maxwidth();

    .wrapper {
        @include accessibility(36rem) {
            column-count: 2;
            column-gap: 1.5rem;
        }

        @include accessibility(70rem) {
            column-count: 3;
        }

        a {
            break-inside: avoid;;
            position: relative;
            display: block;
            margin-bottom: 1.5rem;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                bottom: 0;
                left: 0;
                @include scrim-gradient(to top, $color_tertiary);
                z-index: 1;

                @at-root body[data-contrast-mode=on] & {
                    display: none;
                }

                @media (prefers-reduced-motion: no-preference) {
                    transition: all .25s ease-in-out;
                }
            }

            .account {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                padding: .375rem .625rem;
                color: white;
                font-weight: 700;
                font-size: .875rem;
                z-index: 2;

                &:empty {
                    display: none;
                }

                @at-root body[data-contrast-mode=on] & {
                    background-color: black;
                    border: 1px solid white;
                    border-top: 0;
                }

                svg {
                    width: 1.125rem;
                    height: 1.125rem;
                    margin-right: .625rem;

                    path {
                        fill: currentColor;
                    }
                }

                span {
                    width: calc(100% - 1.625rem);
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }

            &:hover,
            &:focus {
                &::after {
                    top: 0%;
                }
            }
        }
    }
}