.airport-project-detail-header {
    $breakpoint_to_flex : 50rem;
    @include maxwidth();
    margin-bottom: 2rem;

    @include accessibility($breakpoint_to_flex) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .left {
        h1 {
            margin: 0 0 .5rem;
        }

        div {
            color: #a4a4a4;
        }
    }

    .right {
        margin-top: 2rem;

        @include accessibility($breakpoint_to_flex) {
            margin-top: 0;
            margin-left: 2rem;
        }
    }

    + .block {
        margin-top: 3rem;
    }
}