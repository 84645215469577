.board-staff-listing {
    @include maxwidth();

    .h2 {
        text-align: center;
    }

    .wrapper {
        @include columns(3.25rem, 3.8125rem, (
            32rem : 2,
            52rem : 3,
            72rem : 4
        ));
    }
}