.page-contact {
    .content-wrapper {
        @include maxwidth();

        @include accessibility($breakpoint_to_sidebar) {
            display: flex;
        }

        > div {
            margin-bottom: $margin_sections;

            @include accessibility($breakpoint_to_sidebar) {
                flex: 1 1 auto;
                margin-bottom: 0;
            }

            .block {
                padding-right: 0;
                padding-left: 0;

                &:first-child :first-child {
                    margin-top: 0;
                }
            }
        }

        aside {
            @include accessibility($breakpoint_to_sidebar) {
                flex: 1 1 24.625rem;
                @include scale(min-width, 21.625rem, $breakpoint_to_sidebar, 27.625rem, $max_width_content);
                max-width: 27.625rem;
                margin-left: 5rem;
            }

            .contact-info {
                line-height: 1.875;

                &:not(:last-of-type) {
                    margin-bottom: 3rem;
                }

                header {
                    margin-top: 1rem;
                }

                a {
                    text-decoration: none;
                }
            }
        }
    }
}