.lazy-load {
	display: flex;
	justify-content: center;
	width: 100% !important;
	max-width: 100% !important;
	margin-bottom: 4rem;

	@supports (display: grid) {
		grid-column: -1 / 1;
		text-align: center;
	}
}