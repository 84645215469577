&.full-bleed {
	height: 37.5rem;
	position: relative;

	.video {
        display: none;

        @media (prefers-reduced-motion: no-preference) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            overflow: hidden;
            z-index: 2;

            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
	}

	figure,
	picture {
		height: 100%;
		padding-bottom: 0;
	}
}