&.form {
	@include maxwidth();

	.message {
		display: flex;
		align-items: center;
		margin-bottom: 3rem;
		padding: 1.25rem 1.375rem;
		background: rgba(#ccc, .12);
		border: 1px solid rgba(#ccc, .29);

		.icon {
			width: 2.1875rem;
			height: 2.1875rem;
			margin-right: 1rem;
			padding: .1875rem;

			svg {
				display: block;

				path {
					fill: black;
				}
			}
		}

		.text {
			header {
				font-weight: 600;
			}

			p {
				margin: 0;
			}
		}

		&.error {
			$color_error : #dc1515;
			background: rgba($color_error, .12);
			border: 1px solid rgba($color_error, .29);
		}

		&.notice {
			$color_notice : #ffefbd;
			background: rgba($color_notice, .63);
			border: 1px solid $color_notice;
		}

		&.success {
			$color_success : #a9e962;
			background: rgba($color_success, .13);
			border: 1px solid rgba($color_success, .41);
		}
	}

	.freeform-form-has-errors {
		display: none;
	}

	.freeform-row {
		$color_border		: #ccc;
		$color_error		: #dc1515;
		$color_success		: #a0be6f;
		$border_radius		: 0;

		.freeform-column {
			.freeform-input {
				outline: none;

				&:invalid {
					border-color: $color_error;
				}

				&:valid {}
			}

			.errors {
				color: $color_error;
				font-size: .875rem;
			}

			label {
                margin-bottom: .8125rem;
				color: #1a1919;
				font-weight: 400;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                }

				&.freeform-required:after {
					color: $color_error;
				}
			}

			input,
			select,
			textarea {
				font-size: .875rem;
				line-height: 1.2;
				border: 1px solid $color_border;
				border-radius: $border_radius;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                    background-color: black;
                    border-color: white;
                }

				&::placeholder {
					color: #ccc;

                    @at-root body[data-contrast-mode=on] & {
                        color: white;
                    }
				}
			}

			input,
			textarea {
				display: block;
				padding: .8125rem .75rem;
			}

			input[type=file] {
				padding: 1.25rem;
				border: 1px dashed #9b9b9b;
			}

			input[type=number] {
				padding-top: .875rem;
			}

			select {
                appearance: none;
				padding: .8125rem 1.75rem .8125rem .75rem;
				background: white url(/dist/svgs/chevron-down.svg) calc(100% - .625rem) 50% / .625rem no-repeat scroll;
			}

			.freeform-input-only-label,
			label[for=form-input-checkboxGroup] ~ label,
			label[for=form-input-radioGroup] ~ label {
				display: flex;

				.freeform-input {
					position: relative;
					top: .25rem;
					width: 1.1875rem;
					height: 1.1875rem;
					margin-right: 1rem;
					border: 2px solid black;
				}
			}

			button {
				@extend .rd-button;

                @media (prefers-reduced-motion: no-preference) {
                    transition: all .25s ease-in-out;
                }
			}
		}
	}

	&.site-search form {
		display: flex;

		input[type=search] {
			flex: 1 1 auto;
			padding: .9375rem;
			margin-right: .375rem;
			border: 1px solid #ccc;
			border-radius: 0;
		}

		button {
			flex: 0 0 auto;
		}
	}
}