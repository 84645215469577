&.text-testimonials {
    $breakpoint_to_flex : 50rem;
    @include maxwidth();

    @include accessibility($breakpoint_to_flex) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .copy {
        background-color: white;

        @at-root body[data-contrast-mode=on] & {
            background-color: black;
        }

        @include accessibility($breakpoint_to_flex) {
            flex: 1 1 40%;
            max-width: 40%;
        }
    }

    .testimonials {
        position: relative;
        margin-top: 5rem;

        @include accessibility($breakpoint_to_flex) {
            flex: 1 1 54%;
            max-width: 54%;
            margin-top: 0;
        }

        .testimonial {
            padding: 3rem 1.25rem;

            blockquote {
                position: relative;
                margin: 0;
                padding-top: 3rem;
                @include scale(padding-right, 1rem, 20rem, 3.5625rem, $breakpoint_to_flex);
                padding-bottom: 6rem;
                @include scale(padding-left, 1rem, 20rem, 3.5625rem, $breakpoint_to_flex);
                font-size: 1.125rem;
                line-height: 1.777777777777778;
                background-color: white;
                border: 1px solid #e4e4e4;
                box-shadow: 6px 14px 20px 0 rgba(black, .16);

                @at-root body[data-contrast-mode=on] & {
                    background-color: black;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: auto;
                    bottom: calc(100% - 1rem);
                    @include scale(left, 1rem, 20rem, 3.5625rem, $breakpoint_to_flex);
                    width: 4rem;
                    height: 3.25rem;
                    background-image: url(/dist/svgs/text-testimonial-quote-before.svg);
                    background-size: 100%;

                    @at-root body[data-contrast-mode=on] & {
                        background-image: url(/dist/svgs/text-testimonial-quote-before-contrast.svg);
                    }
                }

                p {
                    color: black;

                    @at-root body[data-contrast-mode=on] & {
                        color: white;
                    }

                    &:first-child {
                        margin-top: 0;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                cite {
                    display: flex;
                    margin-top: 2.25rem;
                    color: black;
                    font-style: normal;

                    @at-root body[data-contrast-mode=on] & {
                        color: white;
                    }

                    .img {
                        position: relative;
                        width: 3.625rem;
                        height: 3.625rem;
                        margin-right: 1.5rem;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            width: 3.75rem;
                            height: 3.75rem;
                            background-image: linear-gradient(172deg, $color_primary 0%, $color_secondary 100%);
                            border-radius: 50%;
                            z-index: 1;
                            transform: translate(-50%, -50%);
                        }

                        picture {
                            position: relative;
                            z-index: 2;
                            background-color: white;
                            border: 2px solid white;
                            border-radius: 50%;
                            overflow: hidden;

                            img {

                            }
                        }
                    }

                    .name-title {
                        .name {
                            font-weight: 700;
                        }

                        .job-title {}
                    }
                }
            }

            &:first-child:last-child blockquote {
                padding-bottom: 2.25rem;
            }
        }

        .controls {
            position: absolute;
            bottom: 5.25rem;
            display: flex;
            justify-content: flex-start;
            @include scale(padding-left, 2.25rem, 20rem, 4.8125rem, $breakpoint_to_flex);

            .prev,
            .next {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 1.875rem;
                height: 1.875rem;
                padding: 0;
                color: white;
                background-color: $color_primary;
                border: 0;
                border-radius: 50%;

                @at-root body[data-contrast-mode=on] & {
                    background-color: black;
                    border: 1px solid white;
                }

                @media (prefers-reduced-motion: no-preference) {
                    transition: all .25s ease-in-out;
                }

                &:hover,
                &.tns-nav-active {
                    background-color: $color_tertiary;

                    @at-root body[data-contrast-mode=on] & {
                        color: black;
                        background-color: white;
                    }
                }

                svg {
                    width: .5rem;
                    height: .8125rem;

                    path {
                        fill: currentColor;
                    }
                }
            }

            .prev {
                margin-right: .625rem;
            }
        }
    }
}