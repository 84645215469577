&.call-to-action {
    $breakpoint_flex : 60rem;
    @include maxwidth();

    .wrapper {
        position: relative;
        padding: 3rem $padding_content;
		color: white;
        text-align: center;
        background-image: linear-gradient(178deg, $color_quaternary 0%, $color_secondary 100%);
        border-radius: 6px;
        overflow: hidden;

        @at-root body[data-contrast-mode=on] & {
            background-color: black;
            background-image: none;
            border: 2px solid white;
        }

		@include accessibility($breakpoint_flex) {
			display: flex;
			align-items: center;
            text-align: left;
		}

        > svg {
            position: absolute;
            bottom: -.25rem;
            left: 50%;
            width: auto;
            height: 100%;
            opacity: .2;
            transform: translateX(-50%);

            @at-root body[data-contrast-mode=on] & {
                display: none;

                @include accessibility($breakpoint_flex) {
                    display: block;
                }
            }

            @include accessibility($breakpoint_flex) {
                left: -2rem;
                width: 15rem;
                height: auto;
                opacity: 1;
                transform: translateX(0);
            }
        }

		.copy {
            margin-bottom: 2rem;

			@include accessibility($breakpoint_flex) {
                flex: 1 1 auto;
				margin-right: 2rem;
				margin-bottom: 0;
                padding-left: 10rem;
			}

            header {
                font-size: 1.5rem;
            }
		}

		.link {
            @include accessibility($breakpoint_flex) {
                flex: 1 1 auto;
                margin-right: 4.5rem;
            }

            a {
                white-space: nowrap;
            }
		}
	}
}