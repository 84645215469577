&.board-staff {
    picture {
        margin-bottom: 1rem;
    }

    header {
        color: #696969;
        font-weight: 700;
        font-size: 1.25rem;

        @at-root body[data-contrast-mode=on] & {
            color: white;
        }
    }

    .job-title {
        color: #696969;

        @at-root body[data-contrast-mode=on] & {
            color: white;
        }

        + .bio {
            margin-top: 1rem;
        }
    }

    .bio {
        font-size: .875rem;
        line-height: 1.285714285714286;
    }

    header,
    .job-title,
    .bio {
        + .phone,
        + .email {
            position: relative;
            margin-top: 1.5rem;
            padding-top: 1.5rem;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 2.75rem;
                height: 3px;
                background-color: $color_quaternary;
            }
        }
    }
}