&.text-downloads {
    $breakpoint_to_flex : 60rem;
	@include maxwidth();

	@include accessibility($breakpoint_to_flex) {
        display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

    > .text {
        background-color: white;

        @at-root body[data-contrast-mode=on] & {
            background-color: black;
        }

		@include accessibility($breakpoint_to_flex) {
			flex: 1 1 46%;
            min-width: 46%;
            max-width: 46%;
		}

        .h2, .h3, .h4, .h5, .h6 {
            &:first-child {
                margin-top: 0;
            }
        }

        .buttons {
            margin-top: 2.5rem;
        }
	}

	.downloads {
		position: relative;
        margin: 3rem 0 0;

		@include accessibility($breakpoint_to_flex) {
			flex: 1 1 46%;
            min-width: 46%;
            max-width: 46%;
            margin: 0;
		}

        header {
            margin: 0 0 1.5rem 0;
            padding-bottom: .75rem;
            border-bottom: 4px solid $color_quaternary;

            @at-root body[data-contrast-mode=on] & {
                border-color: white;
            }
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 1rem;
                padding-bottom: .75rem;

                &:not(:last-of-type) {
                    border-bottom: 1px solid #d8d8d8;
                }

                a {
                    display: inline-flex;
                    align-items: center;

                    svg {
                        width: 1.375rem;
                        height: 1.375rem;
                        margin-right: .75rem;

                        path {
                            fill: currentColor;
                        }
                    }
                }
            }
        }
	}
}