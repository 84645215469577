.embed-container {
	position: relative;
	max-width: 100%;
	height: 0;
	padding-bottom: 56.25%;
	overflow: hidden;

	@supports (aspect-ratio: 16 / 9) {
		aspect-ratio: 16 / 9;
		height: auto;
		padding-bottom: 0;
	}

	iframe,
	object,
	embed,
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}