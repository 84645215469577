&.large-image-grid {
    @include maxwidth();

    .wrapper {
        @include columns(2.5rem, 2.5rem, (
            50rem : 2
        ));
    }

    a {
        display: block;

        @at-root body[data-contrast-mode=on] & {
            text-decoration: none;
        }

        &:hover,
        &:focus {
            outline: 2px solid;
            outline-offset: 1px;
        }
    }

    header {
        margin-top: 1rem;
    }

    p {
        color: #383838;
        font-weight: 400;

        @at-root body[data-contrast-mode=on] & {
            color: white;
        }
    }
}