.breadcrumbs {
    @include maxwidth();
    margin-top: 1.75rem;
    margin-bottom: 2.375rem;

    @media print {
        display: none !important;
    }

    ul {
        $breakpoint_to_row : 36rem;
        list-style: none;
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0 0 1rem;

        @include accessibility($breakpoint_to_row) {
            flex-direction: row;
            align-items: flex-end;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 2.75rem;
            height: .1875rem;
            background-color: $color_quaternary;

            @at-root body[data-contrast-mode=on] & {
                background-color: white;
            }
        }

        li {
            display: inline-flex;
            color: #696969;

            @at-root body[data-contrast-mode=on] & {
                color: white;
            }

            &:not(:last-child)::after {
                content: '\00a0\00a0/\00a0\00a0';
            }

            a {
                color: inherit;
                text-decoration: none;

                &:hover,
                &:focus {
                    color: $color_primary;

                    @at-root body[data-contrast-mode=on] & {
                        color: white;
                    }
                }
            }

            h1,
            strong {
                display: inline-block;
                margin: 0;
                color: $color_tertiary;
                font-weight: 700;
                font-size: 1rem;
                letter-spacing: .016428571428571em;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                }
            }
        }
    }

    + .block {
        margin-top: 2rem;
    }
}