&.text {
	@extend %clearfix;
	@include maxwidth();

	img:not(.img-left):not(.img-right) {
		display: block;
		max-width: 90% !important;
		height: auto !important;
		margin: 2em auto;
	}

	.img-left {
		float: left;
		max-width: 35%;
		height: auto !important;
		margin-right: 3rem;
		margin-bottom: 1.5rem;
	}

	.img-right {
		float: right;
		max-width: 35%;
		height: auto !important;
		margin-bottom: 1.5rem;
		margin-left: 3rem;
	}

    .no-results {
        margin: #{$margin_sections * 2} 0;
        text-align: center;
    }
}