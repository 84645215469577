&.search-results {
	@include maxwidth();

	.search-result {
		padding-bottom: 1.5rem;

		&:not(:last-of-type) {
			margin-bottom: 2rem;
			border-bottom: 1px solid #d5d5d5;
		}

		header {
			margin-bottom: .5rem;
			font-weight: 600;
		}

		p {
			margin: .5rem 0;
		}
	}
}