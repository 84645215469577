.true-cost-calculator {
    @include maxwidth();

    label {
        display: block;
        margin-bottom: 3rem;

        span {
            display: block;
            margin-bottom: .8125rem;
            color: #1a1919;
            font-weight: 700;

            @at-root body[data-contrast-mode=on] & {
                color: white;
            }
        }

        input {
            display: block;
            padding: .8125rem .75rem;
            font-size: .875rem;
            line-height: 1.2;
            border: 1px solid #ccc;
            border-radius: 0;

            @at-root body[data-contrast-mode=on] & {
                color: white;
                background-color: black;
                border-color: white;
            }

            &::placeholder {
                color: #ccc;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                }
            }
        }
    }

    button {
        @extend .rd-button;
    }

    table {
        $breakpoint_to_table : 50rem;
        margin-bottom: 3rem;

        &[aria-hidden=true] {
            display: none;
        }

        &[aria-hidden=false] {
            display: block;

            @include accessibility($breakpoint_to_table) {
                display: table;
            }
        }

        thead {
            display: none;

            @include accessibility($breakpoint_to_table) {
                display: table-header-group;
            }

            tr {
                border-bottom: 0;

                th {
                    color: white;
                    font-size: .9375rem;
                    background-color: $color_tertiary;
                    border-bottom: 0;

                    @at-root body[data-contrast-mode=on] & {
                        background-color: black;
                        border-top: 1px solid white;
                    }

                    &:first-child {
                        background-color: white;

                        @at-root body[data-contrast-mode=on] & {
                            background-color: black;
                            border-top: 0;
                        }
                    }

                    &:not(:first-child) {
                        border-left: 1px solid rgba(white, .5);

                        @at-root body[data-contrast-mode=on] & {
                            border-color: white;
                        }
                    }

                    @at-root body[data-contrast-mode=on] & {
                        &:last-child {
                            border-right: 1px solid white;
                        }
                    }
                }
            }
        }

        tbody {
            display: block;

            @include accessibility($breakpoint_to_table) {
                display: table-row-group;
            }

            tr {
                display: block;
                margin-bottom: .75rem;
                padding: 0;

                @include accessibility($breakpoint_to_table) {
                    display: table-row;
                }

                th {
                    display: block;
                    margin-bottom: .5rem;
                    padding: .75rem;
                    color: white;
                    font-size: .875rem;
                    text-align: center;
                    background-color: $color_tertiary;

                    @at-root body[data-contrast-mode=on] & {
                        background-color: black;
                    }

                    @include accessibility($breakpoint_to_table) {
                        display: table-cell;
                        padding: 1rem .75rem;
                        text-align: left;
                        border-top: 1px solid rgba(white, .5);
                    }

                    @at-root body[data-contrast-mode=on] & {
                        @include accessibility($breakpoint_to_table) {
                            border-color: white;
                            border-left: 1px solid white;
                            border-bottom: 1px solid white;
                        }
                    }
                }

                td {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: .375rem .5rem;
                    text-align: right;
                    background-color: white !important;

                    @at-root body[data-contrast-mode=on] & {
                        color: white;
                        background-color: black !important;
                    }

                    &::before {
                        content: attr(data-before);
                        padding-right: .75rem;
                        font-weight: 700;
                        font-size: .9375rem;
                        text-align: left;
                        background-color: white;
                        z-index: 2;

                        @at-root body[data-contrast-mode=on] & {
                            color: white;
                            background-color: black;
                        }
                    }

                    @include accessibility($breakpoint_to_table) {
                        display: table-cell;
                        padding: 1rem .75rem;
                        border: 1px solid rgba($color_tertiary, .5);

                        &::before,
                        &::after {
                            display: none;
                        }
                    }

                    @at-root body[data-contrast-mode=on] & {
                        @include accessibility($breakpoint_to_table) {
                            border-color: white;
                        }
                    }

                    input {
                        display: inline-block;
                        width: 8rem;
                        padding: .8125rem .75rem;
                        font-size: .875rem;
                        line-height: 1.2;
                        text-align: right;
                        border: 1px solid #ccc;
                        border-radius: 0;

                        @at-root body[data-contrast-mode=on] & {
                            color: white;
                            background-color: black;
                            border-color: white;
                        }

                        &::placeholder {
                            color: #ccc;

                            @at-root body[data-contrast-mode=on] & {
                                color: white;
                            }
                        }
                    }

                    span {
                        position: relative;
                        display: inline-block;
                        padding-left: .5rem;
                        background-color: white;
                        z-index: 2;

                        @at-root body[data-contrast-mode=on] & {
                            color: white;
                            background-color: black;
                        }

                        &.cost-to-drive,
                        &.cost-to-travel,
                        &.true-cost {
                            &::before {
                                content: '$';
                            }
                        }
                    }
                }

                &:not(:first-child) td::after {
                    content: '';
                    position: absolute;
                    bottom: .75rem;
                    right: .5rem;
                    left: .5rem;
                    border-bottom: 1px dotted;
                    z-index: 1;
                }
            }
        }
    }
}