&.company-logos {
    @include maxwidth();
    overflow: hidden;

    .h2 {
        line-height: 1;
        text-align: center;
    }

    .wrapper {
        @include columns(3.5rem, 3.5rem, (
            22rem : 2,
            44rem : 3,
            66rem : 4,
        ), false);
        justify-content: center;
    }
}