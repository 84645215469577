&.featured-latest-news {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem;
    background-image: linear-gradient(176deg, #f9f9f9 0%, #eaeaea 100%);

    @at-root body[data-contrast-mode=on] & {
        background-color: black;
        background-image: none;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url(/dist/svgs/fort-wayne-international-airport-icon-white.svg);
        background-position: 50% 105%;
        background-size: 100%;
        background-repeat: no-repeat;
        z-index: 1;
        opacity: .5;

        @at-root body[data-contrast-mode=on] & {
            display: none;
        }

        @include accessibility(64rem) {
            background-position: -5rem center;
            background-size: auto 105%;
        }
    }

    .wrapper {
        @include maxwidth();
        position: relative;
        z-index: 2;

        h2 {
            margin: 0 0 3rem;
            text-align: center;
        }

        .news {
            @include columns(4.5rem, 4.5rem, (
                60rem : 3
            ));

            .category {
                color: $color_quaternary;
                font-weight: 700;
                text-transform: uppercase;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                }
            }

            a {
                display: inline-block;
                margin: 1rem 0;
                color: $color_tertiary;
                @include scale(font-size, 1.125rem, 20rem, 1.375rem, 60rem);
                line-height: 1.136363636363636;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                }
            }

            time {
                display: block;
                color: black;

                @at-root body[data-contrast-mode=on] & {
                    color: white;
                }
            }
        }
    }
}