.accessibility-options {
    $breakpoint_to_flex : 48rem;
    @include maxwidth();

	@include accessibility($breakpoint_to_flex) {
		display: flex;
		justify-content: space-between;
	}

	> div {
		@include accessibility($breakpoint_to_flex) {
			flex: 1 1 46%;
			max-width: 46%;
		}

		p {
			margin-bottom: 1.5rem;
		}

		.rd-button {
			width: 100%;
			margin-bottom: 1.5rem;
		}
	}

}