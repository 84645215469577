.page-payment-form {
    .payment-form {
        @include maxwidth(48.75rem);

        .wrapper {
            padding-top: 2.5rem;
            @include scale(padding-right, 1rem, 20rem, 2.5rem, 50rem);
            padding-bottom: 2.5rem;
            @include scale(padding-left, 1rem, 20rem, 2.5rem, 50rem);
            background-image: linear-gradient(175deg, #fcfcfc 1%, #f2f2f2 100%);
            border: 1px solid #e7e7e7;
            border-radius: 5px;

            @at-root body[data-contrast-mode=on] & {
                background-color: black;
                background-image: none;
                border-color: white;
            }

            header {
                position: relative;
                margin-bottom: 1.5rem;
                padding-bottom: 1rem;

                &::after {
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 4rem;
                    height: 3px;
                    background-color: $color_quaternary;

                    @at-root body[data-contrast-mode=on] & {
                        background-color: white;
                    }
                }
            }

            .block.form {
                padding-right: 0;
                padding-left: 0;

                h2, h3, h4, h5, h6 {
                    margin: 0;
                }

                .success {
                    padding: 1rem;
                    color: white;
                    font-weight: 700;
                    background-color: $color_quaternary;
                    border-radius: 5px;

                    @at-root body[data-contrast-mode=on] & {
                        color: white;
                        background-color: black;
                        border: 1px solid white;
                    }
                }

                form.remove {
                    margin-bottom: 2rem;

                    ul {
                        margin-bottom: 0;
                    }

                    button {
                        @extend .rd-button;
                        margin-left: 2.5rem;
                        padding: .25rem .5rem;
                        border-radius: .25rem;
                    }
                }

                ul + .rd-button.small {
                    margin: -.5rem 0 2rem 2.5rem;
                }

                ul.errors {
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                    color: #dc1515;
                    background-color: rgba(#dc1515, .1);
                    border-radius: 5px;

                    &:empty {
                        display: none;
                    }

                    @at-root body[data-contrast-mode=on] & {
                        color: white;
                        background-color: black;
                        border-color: white;
                    }
                }

                .freeform-row {
                    display: flex;
                    justify-content: space-between;
                    margin: 0 -15px;

                    .freeform-column {
                        flex: 1 0;
                        padding: 10px 0;
                        margin: 0 15px;
                        box-sizing: border-box;

                        &.freeform-column-content-align-center {
                            display: flex;
                            justify-content: center;
                        }

                        label,
                        input,
                        select {
                            display: block;
                            width: 100%;
                        }

                        label.freeform-required::after {
                            content: "*";
                            color: #dc1515;
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}