.buttons {
    display: flex;
    flex-wrap: wrap;
    margin-right: -.625rem;
    margin-left: -.625rem;
    overflow: hidden;

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }

    &.space-around {
        justify-content: space-around;
    }

    &.space-between {
        justify-content: space-between;
    }

    .rd-button {
        margin: .625rem;
    }
}

.rd-button {
	display: inline-flex;
    justify-content: center;
    align-items: center;
	padding: .8125rem 2.5rem;
	color: white;
	font-weight: 700;
    line-height: 1.1875;
	text-align: center;
	text-decoration: none !important;
	background-color: $color_primary;
	border: 2px solid $color_primary;
    border-radius: 1000rem;

    @at-root body[data-contrast-mode=on] & {
        background-color: black;
        border-color: white;
    }

    svg {
        width: 1.25rem;
        height: 1.25rem;

        &:first-child {
            margin-right: .75rem;
        }

        &:last-child {
            margin-left: .75rem;
        }

        path {
            fill: currentColor;
        }
    }

    &.small {
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
    }

	&:hover,
	&:focus,
    &.active {
		color: white;
		background-color: $color_tertiary;
		border-color: $color_tertiary;

        @at-root body[data-contrast-mode=on] & {
            color: black;
            background-color: white;
            border-color: white;
        }
	}

    &.white {
        color: black;
        background-color: white;
        border-color: white;

        @at-root body[data-contrast-mode=on] & {
            color: white;
            background-color: black;
        }

        &:hover,
        &:focus,
        &.active {
            color: white;
            background-color: transparent;
            border-color: white;

            @at-root body[data-contrast-mode=on] & {
                color: black;
                background-color: white;
            }
        }
    }

    &.a2a_dd {
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        color: #383838;
        text-transform: uppercase;
        background-color: transparent;
        border-color: #979797;

        @at-root body[data-contrast-mode=on] & {
            color: white;
            border-color: white;
        }

        &:hover,
        &:focus,
        &.active {
            color: white;
            background-color: #383838;
            border-color: #383838;

            @at-root body[data-contrast-mode=on] & {
                color: black;
                background-color: white;
                border-color: white;
            }
        }
    }
}
