&.company-logo {
    text-align: center;

    figure,
    picture {
        margin-bottom: 1rem;
    }

    header {
        margin-bottom: .5rem;
        color: #383838;
        font-weight: 400;
        font-size: 1.375rem;
        line-height: 1.272727272727273;

        @at-root body[data-contrast-mode=on] & {
            color: white;
        }
    }

    a {
        font-weight: 400;
    }
}